import getData from "@/__main__/get-data.mjs";
import LoLPros from "@/game-lol/models/lol-probuild-pros.mjs";
import LolProbuildTeams from "@/game-lol/models/lol-probuild-teams.mjs";
import * as API from "@/game-lol/utils/api.mjs";

function fetchData() {
  const promises = [];

  promises.push(
    getData(
      API.getProbuildTeams(),
      LolProbuildTeams,
      ["lol", "proBuildTeams"],
      {
        shouldFetchIfPathExists: false,
      },
    ),
  );
  promises.push(
    getData(API.getProbuildPlayers(), LoLPros, ["lol", "proBuildPros"], {
      shouldFetchIfPathExists: false,
    }),
  );

  return Promise.all(promises);
}

export default fetchData;
